<template>
  <div class=""></div>
</template>

<script>

import { onMounted } from 'vue'

export default {
  name: 'opsReport',
  setup() {
    onMounted(async () => {
      window.open('http://10.44.31.5/#/login', '_blank')
    })
    return {}
  }
}
</script>

<style lang="scss" scoped>

</style>